<template>
  <div>
    <div class="form-group">
      <label for="">Medio de pago</label>
      <app-select-payment-method
        v-model="payment_method"
      ></app-select-payment-method>
    </div>
    <div class="form-group">
      <label for="">Pago</label>
      <app-input-money
        :quantity.sync="pay"
        :moneyCode="money_code"
      ></app-input-money>
    </div>
    <div class="form-group">
      <label for="">Vuelto</label>
      <app-input-money
        :quantity.sync="pay_change"
        :moneyCode="money_code"
      ></app-input-money>
    </div>
    <app-button-submit
      @click="$emit('submit', $data)"
      text="Pagar"
    ></app-button-submit>

    <!-- {{total}} -->
  </div>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
    pay: undefined,
    pay_change: undefined,
    money_code: 1,
    payment_method: undefined
  }),
  props: {
    total: {
      default: 0
    }
  },
  watch: {
    pay() {
      this.pay_change = this.pay >= this.total ? this.pay - this.total : 0;
    },
    total() {
      this.pay = this.total;
    }
  }
};
</script>

<style>
</style>
