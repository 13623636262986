<template>
  <div>
    <h4>PAGOS PENDIENTES</h4>
    <legend>Tratamientos</legend>
    <table class="table table-responsive table-sm">
      <tbody>
        <tr v-for="l in treatment_debts" :key="l.id">
          <td>
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  l.client_id +
                  '/treatment-plans/' +
                  l.treatment_plan_id
              "
              >#{{ l.treatment_plan_id }} - {{ l.plan_name }}
            </router-link>
          </td>
          <td>{{ l.product_name }}</td>
          <td class="text-right">
            <app-span-money
              :moneyCode="l.money_code"
              :quantity="l.unit_price"
            ></app-span-money>
          </td>
          <td>
            <!-- <button
              class="btn btn-light btn-sm"
              @click="
                loadTreatmentPlan(l.treatment_plan_id);
                $refs.dTtopay.show();
              "
            >
              <i class="fa fa-money-bill"></i>
            </button> -->
          </td>
        </tr>
      </tbody>
    </table>
    <legend>FINANCIACIONES</legend>

    <table class="table table-responsive">
      <thead>
        <tr>
          <th></th>
          <th>FINANCIACION</th>
          <th>TRATAMIENTO</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="l in treatment_exp_dues" :key="l.id">
          <td>
            <app-checkbox v-model="l.to_pay" placeholder="">
              <small v-show="dateOut(l.expiration)" class="text-danger">
                DEUDA
              </small>
            </app-checkbox>
          </td>
          <td>
            <router-link
              class="text-dark"
              :to="'/dental-clinic/financings/' + l.financing_id"
            >
              <span>Financiacion #{{ l.financing_id }}</span>
            </router-link>
          </td>
          <td>
            <router-link
              class="text-dark"
              :to="
                '/dental-clinic/patients/' +
                  l.client_id +
                  '/treatment-plans/' +
                  l.treatment_plan_id
              "
              >#{{ l.treatment_plan_id }} -
              {{ l.treatment_plan_name }}</router-link
            >
          </td>
          <td>{{ ordenCuota(l.due) }}</td>
          <td>{{ l.expiration }}</td>
          <td>
            <app-span-money
              :moneyCode="1"
              :quantity="l.amount - l.amount_paid"
            ></app-span-money>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary mb-3" @click="$refs.dDtopay.show()">
      Pagar
    </button>

    <app-modal-basic ref="dTtopay">
      <div v-if="!!tmp_treatment.id">
        <h4>TRATAMIENTO {{ tmp_treatment.id }}</h4>
        <ToPay
          @paidOut="getDebts()"
          :plan="tmp_treatment"
          :details="tmp_treatment.details"
        ></ToPay>
      </div>
    </app-modal-basic>

    <app-modal-basic ref="dDtopay">
      <h4>PAGAR FINANCIACION</h4>
      <table class="table">
        <tr v-for="l in treatment_exp_dues.filter(x => x.to_pay)" :key="l.id">
          <td>{{ ordenCuota(l.due) }}</td>
          <td>
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  l.client_id +
                  '/treatment-plans/' +
                  l.treatment_plan_id
              "
              >#{{ l.treatment_plan_id }} -
              {{ l.treatment_plan_name }}</router-link
            >
          </td>
          <td>
            <app-span-money
              :moneyCode="1"
              :quantity="l.amount - l.amount_paid"
            ></app-span-money>
          </td>
        </tr>
      </table>

      <!-- Cargos adicionales comunes -->
      <template v-for="(l, i) in products">
        <div class="form-group" :key="i">
          <label for=""> {{ l }} </label>
          <app-input-money
            :quantity.sync="productPays[i]"
            :moneyCode="1"
          ></app-input-money>
        </div>
      </template>

      <PayCtrl
        ref="pelPayCtrl"
        @submit="payDues($event)"
        :total="total"
      ></PayCtrl>
      <a
        v-show="sale_id"
        :href="backendUrl + 'print/store/sales-ticket/' + sale_id"
        class="btn btn-light mt-2"
      >
        <i class="fa fa-print"></i> Imprimir Ticket
      </a>
    </app-modal-basic>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import ToPay from "../treatments/ToPay";
import { ordenCuota } from "../financings/utils";
import { myMoment } from "../../utils/myMoment";
import PayCtrl from "../../store-module/sales/PayCtrl.vue";

import { mapState } from "vuex";

export default {
  components: { ToPay, PayCtrl },
  data: () => ({
    treatment_debts: {},
    treatment_exp_dues: [],
    tmp_treatment: {},
    backendUrl: process.env.VUE_APP_API_URL,
    sale_id: undefined,
    productPays: {}
  }),
  props: {
    client_id: {}
  },
  mounted() {
    this.getDebts();
  },
  watch: {
    productPays() {}
  },
  computed: {
    ...mapState({
      products: s => s.config.dental.financing.products_to_pay
    }),
    total() {
      let total = 0;
      this.treatment_exp_dues
        .filter(x => x.to_pay)
        .map(x => {
          if (x.to_pay) {
            total += parseFloat(x.amount - x.amount_paid);
          }
        });

      for (let it in this.productPays) {
        total += parseFloat(this.productPays[it]);
      }

      return total;
    }
  },
  methods: {
    dateOut(expiration) {
      return myMoment(expiration, "YYYY-MM-DD").isBefore(myMoment());
    },
    loadTreatmentPlan(plan_id) {
      this.tmp_treatment = {};
      DentalClinicService.getTreatmentPlan(plan_id, {
        addDetails: 1
      }).then(res => (this.tmp_treatment = res));
    },
    payDues({ payment_method, pay, pay_change, money_code }) {
      DentalClinicService.payDebts({
        dues: this.treatment_exp_dues.filter(x => x.to_pay).map(x => x.id),
        payment_method,
        pay,
        pay_change,
        money_code,
        client_id: this.client_id,
        adds: this.productPays
      }).then(
        res => {
          // console.log(res);
          this.sale_id = res;
          window.location.href = `${this.backendUrl}print/store/sales-ticket/${res}`;
        },
        err => {
          this.$store.dispatch("showError", err.response.data.message);
        }
      );
    },
    ordenCuota,
    getDebts() {
      DentalClinicService.getDebts({
        client_id: this.client_id
      }).then(res => {
        this.treatment_debts = res.treatment_debts;
        this.treatment_exp_dues = res.treatment_exp_dues.map(x => ({
          ...x
          // expiration: moment(x.expiration).format("DD/MM/YYYY"),
          // expired:
        }));
      });
    }
    // getTotal() {

    // }
    // getDues(params) {
    //   return new Promise(rsv => {
    //     DentalClinicService.getFinancingDues({...params,
    //       client_id: this.client_id,
    //       paid_out: 0
    //     }).then(res => {
    //       this.dues = res;
    //       rsv(res);
    //     })
    //   })
    // }
  }
};
</script>

<style></style>
